/* eslint-disable no-console */
/* eslint-disable no-return-assign */
import React, { useState, useEffect, memo, useRef } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import cx from 'clsx'
import moment from 'moment'
import {
  getInitialDataActions,
  setOpenPopupTypeAction
} from 'store/thunks/subscription'
import commaNumber from 'comma-number'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TextField from '@material-ui/core/TextField'
import Radio from '@material-ui/core/Radio'
import SnackManager from 'libs/snack'
import Button from '@material-ui/core/Button'
import Collapse from '@material-ui/core/Collapse'
import { useDebounce } from 'use-debounce'

import Modal from 'components/Modal'
import useGa from 'hooks/useGa'
import APIs from 'api'
import PaymentForm from 'components/PaymentForm'

import Loading from 'components/Loading'
import { store } from 'store/config'
import useTranslate from 'hooks/useTranslate'
import s from './UpdateSubscription.module.scss'
import ConfirmModal from './ConfirmUpdateSubscriptionPayment'

function UpdateSubscription({
  inputValue,
  isYearly,
  handleClose,
  handleCancelDemand,
  tenantDetails,
  ...props
}) {
  const { overview } = props
  const {
    last_done_demand: lastDoneDemand,
    last_pending_demand: lastPendingDemand
  } = tenantDetails
  const [memberCount, setMemberCount] = useState({ current: '0', updated: '0' })
  const [debouncedMemberCount] = useDebounce(memberCount, 300)
  const [planType, setPlanType] = useState({ current: 'Monthly', updated: 'Monthly' })
  const [radioValue, setRadioValue] = useState('main') // main, other
  const [isDisable, setIsDisable] = useState(false)
  const [isSmallerCount, setisSmallerCount] = useState(false)
  const [showErrorText, setshowErrorText] = useState(false)
  const [modalData, setmodalData] = useState({})
  const [showModal, setshowModal] = useState(false)
  const [isLoadingModal, setisLoadingModal] = useState(false)
  const [paymentMethod, setPaymentMethod] = useState(null)
  const [nextDate, setNextDate] = useState(null)
  const [proration, setProration] = useState(null)
  const lastProrationDate = useRef(null)
  const dispatch = useDispatch()
  const ga = useGa('subscription')
  const t = useTranslate('subscription')
  const { plansList } = store.getState().subscription
  const proPlansList = plansList.filter(plan => plan.plan_type === 'pro')
  const selectedPrice =
    lastPendingDemand?.next_plan_type === 'Pro'
      ? overview?.plan?.selected_price
      : lastPendingDemand
  const unitAmountYearlyProPlan = proPlansList?.find(plan => plan.id === 3)
    ?.selected_price?.amount

  const isUpdateProPlan =
    lastDoneDemand?.next_plan_type === 'Pro' &&
    lastPendingDemand?.next_plan_type === 'Pro'

  const isUpdateProPlanToEnterprisePlan =
    lastDoneDemand?.next_plan_type === 'Pro' &&
    lastPendingDemand?.next_plan_type === 'Enterprise'

  const isEnterprisePlan =
    lastPendingDemand?.next_plan_type === 'Enterprise' &&
    lastDoneDemand?.next_plan_type === 'Enterprise'

  const isSwitchYearly =
    lastDoneDemand?.next_plan_type === 'Pro' &&
    lastDoneDemand?.next_plan_id === 2 &&
    lastPendingDemand?.next_plan_id === 3

  const isUpdatedEqual =
    Number(memberCount.updated) === Number(memberCount.current) && !isSwitchYearly

  const addedMemberCount = lastDoneDemand
    ? lastPendingDemand.quantity - lastDoneDemand.quantity
    : 0

  function findIdByKey(key) {
    if (key) {
      const obj = proPlansList.find(item => item.invoice_interval === 'year')
      return obj ? obj.id : null
    }
    const obj = proPlansList.find(item => item.invoice_interval === 'month')
    return obj ? obj.id : null
  }
  const planId = findIdByKey(isSwitchYearly)
  const isUpdatedSmaller = () => {
    if (Number(memberCount.updated) < Number(memberCount.current)) {
      const newDifference = Number(memberCount.current) - Number(memberCount.updated)
      const oldDifference =
        Number(memberCount.current) - Number(overview.used_quantity)
      if (oldDifference >= newDifference) {
        setIsDisable(false)
        setisSmallerCount(true)
        setshowErrorText(false)
      } else {
        setisSmallerCount(true)
        setIsDisable(true)
        setshowErrorText(true)
      }
    } else {
      setIsDisable(false)
      setshowErrorText(false)
      setisSmallerCount(false)
    }
  }

  useEffect(() => {
    const currentQuantityStr = String(overview.quantity)
    const updatedQuantityStr = String(overview.quantity + addedMemberCount)
    setMemberCount({
      current: currentQuantityStr,
      updated: updatedQuantityStr
    })
  }, []) // eslint-disable-line

  useEffect(() => {
    isUpdatedSmaller()
  }, [memberCount]) // eslint-disable-line

  useEffect(() => {
    APIs.getDefaultPaymentMethod().then(res => setPaymentMethod(res.data))
    if (isUpdateProPlanToEnterprisePlan) {
      setPlanType({
        current: lastDoneDemand?.next_plan_id === 2 ? 'Monthly' : 'Yearly', // change last plan to last done demand
        updated: 'Enterprise'
      })
    } else if (isEnterprisePlan) {
      setPlanType({
        current: 'Enterprise',
        updated: 'Enterprise'
      })
    } else if (isSwitchYearly) {
      setPlanType({
        current: 'Monthly',
        updated: 'Yearly'
      })
    } else if (isUpdateProPlan) {
      setPlanType({
        current:
          lastDoneDemand?.billing_option.invoice_interval === 'year'
            ? 'Yearly'
            : 'Monthly',
        updated:
          lastPendingDemand?.billing_option.invoice_interval === 'year'
            ? 'Yearly'
            : 'Monthly'
      })
    } else {
      setPlanType({
        current: overview.plan.invoice_interval === 'year' ? 'Yearly' : 'Monthly',
        updated: overview.plan.invoice_interval === 'year' ? 'Yearly' : 'Monthly'
      })
    }
  }, [tenantDetails])

  useEffect(() => {
    if (Number(debouncedMemberCount.updated)) {
      const newProrationDate = moment(new Date())
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')

      lastProrationDate.current = newProrationDate
      setProration(null)
      let body
      if (isSwitchYearly) {
        body = {
          number_of_members: debouncedMemberCount.updated,
          proration_date: newProrationDate,
          plan_id: planId
        }
      } else {
        body = {
          number_of_members: debouncedMemberCount.updated,
          proration_date: newProrationDate,
          plan_id: lastPendingDemand.next_plan_id
        }
      }

      APIs.getcalculateProration({ body }).then(res => {
        setProration(res.data.proration_amount)
        const date = moment(res.data.next_payment_attempt).format('MMMM Do YYYY')
        setmodalData({
          date,
          amount: commaNumber(res.data.next_payment_amount),
          proration: res.data.proration_amount,
          isSmallerCount,
          addedCount: Number(memberCount.updated) - Number(memberCount.current),
          current: memberCount.current,
          updated: memberCount.updated
        })
        setNextDate(date)
      })
    }
  }, [debouncedMemberCount.updated])

  const handleSetMemberCount = (key, value) => {
    if (value.length) {
      setMemberCount(prevState => ({ ...prevState, [key]: value[0] }))
    }
  }

  const handlePayment = async (id, customerInfo) => {
    const info = {
      payment_method: id,
      plan_id: planId,
      number_of_members: inputValue,
      pay_by: 'stripe',
      first_name: customerInfo.firstName,
      last_name: customerInfo.lastName,
      country: customerInfo.country,
      postal_code: customerInfo.postalCode
    }
    const subscriptionInfo = {
      payment_method_id: null,
      plan_id: planId,
      number_of_members: inputValue
    }
    await APIs.addNewPaymentMethod({ body: info }).then(
      res => (subscriptionInfo.payment_method_id = res.data.id)
    )
    if (isSwitchYearly) {
      const body = {
        payment_method_id: subscriptionInfo.payment_method_id,
        plan_id: planId,
        number_of_members: memberCount.updated
      }
      return APIs.changeSubscription({ body }).then(() => {
        ga('switched to yearly')
        SnackManager.success(t(80))
        return dispatch(setOpenPopupTypeAction(''))
      })
    }
    const body = {
      payment_method_id: paymentMethod.id,
      number_of_members: memberCount.updated,
      plan_id: lastPendingDemand.next_plan_id
    }
    return APIs.changeSubscription({ body }).then(() => {
      ga('changed number of members')
      SnackManager.success(t(80))
      return dispatch(setOpenPopupTypeAction(''))
    })
  }

  const handleChangeRadio = value => setRadioValue(value)

  const handleUpdatePlan = () => {
    setisLoadingModal(true)
    if (isSwitchYearly) {
      const info = {
        number_of_members: memberCount.updated,
        payment_method_id: paymentMethod.id,
        plan_id: planId
      }
      APIs.changeSubscription({ body: info })
        .then(() => {
          ga('switched to yearly')
          SnackManager.success(t(80))
          dispatch(getInitialDataActions())
          setisLoadingModal(false)
          handleClose()
          window.location.reload()
          return dispatch(setOpenPopupTypeAction(''))
        })
        .catch(err => {
          if (typeof err === 'string') {
            SnackManager.error(err)
          } else if (err?.message) {
            SnackManager.error(err.message)
          } else if (err && typeof err === 'object') {
            Object.entries(err).forEach(([key, messages]) => {
              messages.forEach(message => {
                SnackManager.error(message)
              })
            })
          } else {
            SnackManager.error('An error occurred. Please try again.')
          }
          setisLoadingModal(false)
        })
    } else {
      const info = {
        payment_method_id: paymentMethod.id,
        number_of_members: memberCount.updated,
        plan_id: lastPendingDemand.next_plan_id
      }
      APIs.changeSubscription({ body: info })
        .then(() => {
          ga('changed number of members')
          SnackManager.success(t(80))
          dispatch(getInitialDataActions())
          setisLoadingModal(false)
          handleClose()
          window.location.reload()
          return dispatch(setOpenPopupTypeAction(''))
        })
        .catch(err => {
          if (typeof err === 'string') {
            SnackManager.error(err)
          } else if (err?.message) {
            SnackManager.error(err.message)
          } else if (err && typeof err === 'object') {
            Object.entries(err).forEach(([key, messages]) => {
              messages.forEach(message => {
                SnackManager.error(message)
              })
            })
          } else {
            SnackManager.error('An error occurred. Please try again.')
          }
          setisLoadingModal(false)
        })
    }
  }

  const renderLeftBox = (isDisabled, title, count, type) => {
    return (
      <div className={cx(s.box, { [s.disable]: isDisabled })}>
        <span className={s.title}>{title}</span>
        <div className={s.inner} data-testid={title}>
          {lastDoneDemand?.next_plan_type}
          <br />
          {lastDoneDemand?.billing_option?.title} {t(117)}
          <br />
          {count === '' ? 0 : Number(count)} {t(116)} <br />
          {type === 'Enterprise'
            ? `${lastDoneDemand?.unit_amount} ${lastDoneDemand?.currency} per ${lastDoneDemand?.billing_option.invoice_interval_count} ${lastDoneDemand?.billing_option.invoice_interval}`
            : type === 'Monthly'
            ? `${lastDoneDemand?.unit_amount} ${lastDoneDemand?.currency} ${t(81)}`
            : `${(unitAmountYearlyProPlan / 12).toFixed(2)} ${
                lastDoneDemand?.currency
              } ${t(81)}`}
          <br />
          {type === 'Yearly' &&
            `${unitAmountYearlyProPlan} ${lastDoneDemand?.currency} per year per member`}
          {type === 'Yearly' && <br />}
          {type === 'Enterprise' ? (
            <>
              Total : {lastDoneDemand?.price} {selectedPrice.currency}
              <br />
              <br />
            </>
          ) : type === 'Monthly' ? (
            <>
              Total : {commaNumber((Number(count) * selectedPrice.amount).toFixed(2))}{' '}
              {selectedPrice.currency} {t(82)}
              <br />
              <br />
            </>
          ) : (
            <>
              Total:{' '}
              {commaNumber((Number(count) * unitAmountYearlyProPlan).toFixed(2))}{' '}
              {lastDoneDemand?.currency}
              {t(83)}
              <br />
            </>
          )}
        </div>
      </div>
    )
  }

  const renderRightBox = (isDisabled, title, count, type) => {
    return (
      <div className={cx(s.box, { [s.disable]: isDisabled })}>
        <span className={s.title}>{title}</span>
        <div className={s.inner} data-testid={title}>
          {lastPendingDemand?.next_plan_type}
          <br />
          {lastPendingDemand?.billing_option?.title} {t(117)}
          <br />
          {count === '' ? 0 : Number(count)} {t(116)} <br />
          {'  '}
          {type === 'Enterprise'
            ? `${lastPendingDemand?.unit_amount} ${lastPendingDemand?.currency} per ${lastPendingDemand?.billing_option.invoice_interval_count} ${lastPendingDemand?.billing_option.invoice_interval} per member`
            : type === 'Monthly'
            ? `${lastPendingDemand?.unit_amount} ${lastPendingDemand?.currency} ${t(
                81
              )}`
            : `${(unitAmountYearlyProPlan / 12).toFixed(2)} ${
                lastPendingDemand?.currency
              } ${t(81)}`}
          <br />
          {type === 'Yearly' &&
            `${unitAmountYearlyProPlan} ${lastPendingDemand?.currency} per year per member`}
          {type === 'Yearly' && <br />}
          {type === 'Enterprise' ? (
            <>
              Total :{' '}
              {commaNumber(
                (Number(count) * lastPendingDemand?.unit_amount).toFixed(2)
              )}{' '}
              {lastPendingDemand?.currency}
              <br />
              <br />
            </>
          ) : type === 'Monthly' ? (
            <>
              Total :{' '}
              {commaNumber(
                (Number(count) * lastPendingDemand?.unit_amount).toFixed(2)
              )}{' '}
              {lastPendingDemand?.currency} {t(82)}
              <br />
              <br />
            </>
          ) : (
            <>
              Total:{' '}
              {commaNumber((Number(count) * unitAmountYearlyProPlan).toFixed(2))}{' '}
              {lastPendingDemand?.currency} {t(83)}
              <br />
            </>
          )}
        </div>
      </div>
    )
  }

  const handleCancel = () => {
    setRadioValue('main')
  }
  const boxDisable = isDisable === false
  const btnText = isSwitchYearly || proration > 0 ? t(103) : t(104)

  return (
    <div className={s.wrapper}>
      <div className={s.head}>
        <h2 className={s.title}>{t(145)}</h2>
      </div>

      <div className={s.inputContainer}>
        <span className={s.label}>{t(86)}</span>
        <div className={s.inputWrapper}>
          <TextField
            disabled
            classes={{ root: s.input }}
            value={memberCount.updated}
            inputProps={{ testid: 'members-input' }}
            onChange={e =>
              handleSetMemberCount('updated', e.target.value.match(/[0-9]+/g) || '')
            }
            variant="outlined"
          />
        </div>
        <Collapse in={showErrorText}>
          <span className={s.errorText}>
            {t(142)} {overview.used_quantity} {t(143)}
            {t(144)}
          </span>
        </Collapse>
      </div>

      <div className={cx({ [s.isDisable]: isDisable })}>
        <div className={s.boxes}>
          {renderLeftBox(
            boxDisable,
            'Current',
            memberCount.current,
            planType.current
          )}
          <div className={cx('icon-back', s.arrowIcon)} />
          {renderRightBox(false, 'Updated', memberCount.updated, planType.updated)}
        </div>

        <span className={s.boxDetails}>
          {t(94)}
          {!isSwitchYearly ? (
            <span style={{ color: '#000' }}>
              {t(95)}
              <strong style={{ color: '#6b75ff' }}> {nextDate} </strong>.
            </span>
          ) : null}
          {t(96)}
          <span> support@bisflow.com </span>
          {t(97)} <span> +46701622602 </span>
        </span>

        {!isDisable ? (
          <>
            {!isSmallerCount ? (
              <>
                <div className={s.innerTitle}>
                  <h3 className={s.title}>{t(98)}</h3>
                </div>
                <div className={s.radios}>
                  <RadioGroup
                    value={radioValue}
                    onChange={e => handleChangeRadio(e.target.value)}
                  >
                    <FormControlLabel
                      classes={{ root: s.radioLabelRoot, label: s.radioLabel }}
                      value="main"
                      data-testid="current-card"
                      control={<Radio color="primary" classes={{ root: s.radio }} />}
                      label={
                        <span className={s.radioName}>
                          {t(99)} ****{paymentMethod?.last4}
                        </span>
                      }
                    />
                    <FormControlLabel
                      classes={{ root: s.radioLabelRoot, label: s.radioLabel }}
                      data-testid="other-card"
                      value="other"
                      control={<Radio color="primary" classes={{ root: s.radio }} />}
                      label={<span className={s.radioName}>{t(100)}</span>}
                    />
                  </RadioGroup>
                </div>
              </>
            ) : null}
          </>
        ) : null}

        <Collapse in={radioValue === 'other'}>
          <PaymentForm
            showDetails={false}
            handlePayment={handlePayment}
            showButton
            buttonText={t(101)}
            onCancel={handleCancel}
          />
        </Collapse>

        <div className={s.submit}>
          <div className={s.calcBox}>
            {!isDisable ? (
              <>
                <>
                  {proration === 0 ? null : (
                    <>
                      <p className={s.total}>
                        {t(102)}
                        {'  '}
                        <span className={s.num}>
                          {proration ? commaNumber(proration) : <Loading show />}
                        </span>{' '}
                        <span className={s.type}>{selectedPrice.currency}</span>
                      </p>
                    </>
                  )}
                </>
              </>
            ) : null}
          </div>

          {radioValue !== 'other' && proration !== null && (
            <div>
              <Button
                variant="text"
                color="primary"
                classes={{ root: s.cancel, label: s.cancelBtnLabel }}
                onClick={handleCancelDemand}
              >
                {t(33)}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setshowModal(true)}
                classes={{ root: s.btn, label: s.btnLabel }}
                disabled={proration === null || proration === 0}
                data-testid="submit"
              >
                {btnText}
              </Button>
            </div>
          )}

          {radioValue !== 'other' && proration === null && <Loading show />}
        </div>
      </div>
      <Modal
        open={showModal}
        onClose={handleCancelDemand}
        classes={{
          modal: s.modal,
          paper: s.paper
        }}
      >
        <ConfirmModal
          onCancel={handleCancelDemand}
          onSubmit={handleUpdatePlan}
          data={modalData}
          isLoadingModal={isLoadingModal}
          isSwitchYearly={isSwitchYearly}
          currency={selectedPrice.currency}
        />
      </Modal>
    </div>
  )
}
UpdateSubscription.propTypes = {
  inputValue: PropTypes.number.isRequired,
  isYearly: PropTypes.bool.isRequired,
  isSwitchYearly: PropTypes.bool,
  overview: PropTypes.shape
}
UpdateSubscription.defaultProps = {
  isSwitchYearly: false,
  overview: null
}

const mapStateToProps = state => {
  return {
    overview: state.subscription.overview
  }
}

export default connect(mapStateToProps, null)(memo(UpdateSubscription))
