/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Router, Switch, Route, Redirect } from 'react-router-dom'
import { Provider, useDispatch, useSelector } from 'react-redux'
import { PersistGate } from 'redux-persist/lib/integration/react'
import { SnackbarProvider } from 'notistack'
import { AnimatedSwitch } from 'react-router-transition'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import CookieConsent from 'react-cookie-consent'
import ReactGA from 'react-ga'
import { getInitialDataActions } from 'store/thunks/subscription'
import ThemeProvider from 'theme'
import LanguageProvider from 'contexts/language/provider'
import { Provider as ReportingProvider } from 'contexts/ReportingContext'
import { Provider as AuthProvider } from 'contexts/AuthContext'
import { Provider as GlobalProvider } from 'contexts/GlobalContext'
import { persistor, store } from 'store/config'
import history from 'libs/history'
import { useStore, AppContainer } from 'hooks'
import { Snackbar } from 'libs/snack'
import ModalConfirm from 'components/ModalConfirm'
import SideBar from 'components/SideBar'
import Header from 'components/Header'
import HeaderBackButton from 'components/HeaderBackButton'
import InitialLoading from 'components/InitialLoading'
import ReadOnly from 'components/ReadOnly'
import SynchronizationStatusBar from 'components/SynchronizationStatusBar'
// import ErrorBoundary from 'components/ErrorBoundary'

import useTranslate from 'hooks/useTranslate'
import ROUTES from './routes'
import {
  AsyncSignin,
  AsyncSignup,
  AsyncAssistant,
  AsyncClients,
  AsyncClientsAdd,
  AsyncCompany,
  AsyncConfirmationCode,
  AsyncOnboarding,
  AsyncForgetPassword,
  AsyncHome,
  AsyncInvoiceSetting,
  AsyncInvoicing,
  AsyncInvoicingHistory,
  AsyncInvoicingItem,
  AsyncInvoicingNew,
  AsyncMembersAdd,
  AsyncMembers,
  AsyncPlanning,
  AsyncProfile,
  AsyncProjectRoles,
  AsyncProjects,
  AsyncProjectsAdd,
  AsyncReports,
  AsyncResetPassword,
  AsyncSettings,
  AsyncSubscription,
  AsyncActivityLog,
  // AsyncSubscriptionBilling,
  AsyncSubscriptionInvoices,
  AsyncTags,
  AsyncTimeApproval,
  AsyncTimeEntry,
  AsyncDashboard,
  AsyncIntegrations,
  AsyncIntegrationsHistory
} from './pages'
import NoMatch from './404'
import Forbidden from './403'
import Terms from './Terms'
import CookiePolicy from './CookiePolicy'
import PrivacyPolicy from './PrivacyPolicy'
import { mapStyles, bounceTransition, snackbarClasses, isLoggedIn } from './utils'

const stripeKey =
  process.env.REACT_APP_STRIPE_KEY ||
  'pk_test_51Ncp9iFxHEEPlCzJDg2kybfK7n0o2wyjq7FvDzqEq8blmKRVxsFoXWtSu73X15cP1zvyXrGt2lKlEIjovDBW0EA700AnX1PqQQ'

const stripePromise = loadStripe(stripeKey)

const RouterConfig = () => {
  const [loading, setLoading] = useState(false)
  const [fulfilledRequestsCount, setFulfilledRequestsCount] = useState(0)
  const [readOnly, setReadOnly] = useState(true)
  const [isReadOnly, setIsReadOnly] = useState(false)
  const [userRole, setUserRole] = useState('')
  const [isUserFreezed, setIsUserFreezed] = useState(false)
  const loggedIn = isLoggedIn()
  const dispatch = useDispatch()
  const overview = useSelector(state => state.subscription.overview)
  const planId = overview?.plan?.id
  const t = useTranslate('general')
  const { getUserInfo, state: authState } = useStore('AuthContext')

  useEffect(() => {
    if (loggedIn) {
      dispatch(getInitialDataActions())
    }
  }, [dispatch, loggedIn])

  useEffect(() => {
    if (overview?.tenant_status === 3 || overview?.tenant_status === 2) {
      setIsReadOnly(true)
    } else {
      setIsReadOnly(false)
    }
  }, [overview])

  const {
    getRegularPermissions,
    getProjectPermissions,
    getTeamPermissions,
    getCountries,
    getCompanyInfo,
    getTimezones
  } = useStore('GlobalContext')
  const PLANNING_HEADER_LINKS = [
    { title: t('headers.0'), path: ROUTES.PLANNING },
    { title: t('headers.1'), path: ROUTES.ASSISTANT }
  ]

  const SUBSCRIPTION_HEADER_LINKS = [
    { title: t('headers.2'), path: ROUTES.SUBSCRIPTION },
    // { title: 'Billing settings', path: ROUTES.SUBSCRIPTION_BILLING },
    { title: t('headers.3'), path: ROUTES.SUBSCRIPTION_INVOICES }
  ]

  const APPROVAL_HEADER_LINKS = [
    // { title: 'Timesheets', path: ROUTES.TIME_APPROVAL }
    // { title: 'Time-off Requests', path: ROUTES.TIME_OFF_APPROVAL }
  ]

  const initApp = async () => {
    const incrementFulfilledReqCounts = () => {
      setFulfilledRequestsCount(currentValue => currentValue + 1)
    }

    try {
      setLoading(true)
      await getUserInfo()
      incrementFulfilledReqCounts()

      await Promise.allSettled([
        getRegularPermissions().then(incrementFulfilledReqCounts),
        getProjectPermissions().then(incrementFulfilledReqCounts),
        getTeamPermissions().then(incrementFulfilledReqCounts)
      ])

      Promise.all([getCountries(), getTimezones(), getCompanyInfo()])

      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (loggedIn) {
      initApp()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setReadOnly(isReadOnly)
    if (overview?.tenant_status === 2) setIsUserFreezed(true)
  }, [isReadOnly])

  function getUserRole(user) {
    if (user.roles?.some(role => role.name === 'Owner')) {
      return 'owner'
    } else if (user.roles?.some(role => role.name === 'Admin')) {
      return 'admin'
    } else return 'regular'
  }

  useEffect(() => {
    const userRole = getUserRole(authState.info)
    setUserRole(userRole)
  }, [authState.info])

  if (loading) {
    return <InitialLoading progress={fulfilledRequestsCount} />
  }

  const fortNoxSyncTimes = overview?.fortnox_sync_times || {}
  const unseenSyncNotices = overview?.fortnox_unseen_notices || {}

  return (
    // <ErrorBoundary>

    <LanguageProvider>
      <ThemeProvider>
        <SnackbarProvider maxSnack={5} classes={snackbarClasses}>
          <Snackbar />

          <ModalConfirm />

          <Router history={history}>
            <ReadOnly
              readOnly={readOnly}
              userRole={userRole}
              isUserFreezed={isUserFreezed}
              isUserLoggedIn={loggedIn}
              planId={planId}
            />

            {['owner', 'admin'].includes(userRole) &&
              overview?.fortnox_unseen_notices.length > 0 && (
                <SynchronizationStatusBar
                  unseenSyncNotices={unseenSyncNotices}
                  fortNoxSyncTimes={fortNoxSyncTimes}
                />
              )}

            <SideBar />

            <AppContainer>
              <Switch>
                <Route exact path={ROUTES.BASE}>
                  {loggedIn ? (
                    <Redirect to={ROUTES.HOME_PATH} />
                  ) : (
                    <Redirect to={ROUTES.SIGNIN} />
                  )}
                </Route>

                <Route exact path={ROUTES.SIGNIN}>
                  <AsyncSignin />
                </Route>

                <Route exact path={ROUTES.SIGNUP}>
                  <AsyncSignup />
                </Route>

                <Route exact path={ROUTES.FORGET_PASSWORD}>
                  <AsyncForgetPassword />
                </Route>

                <Route exact path={ROUTES.RESET_PASSWORD}>
                  <AsyncResetPassword />
                </Route>

                <Route exact path={ROUTES.CONFIRMATION_CODE}>
                  <AsyncConfirmationCode />
                </Route>

                <Route exact path={ROUTES.PRIVACY}>
                  <HeaderBackButton />
                  <PrivacyPolicy />
                </Route>

                <Route exact path={ROUTES.COOKIE_POLICY}>
                  <HeaderBackButton />
                  <CookiePolicy />
                </Route>

                <Route exact path={ROUTES.TERMS}>
                  <HeaderBackButton />
                  <Terms />
                </Route>

                <Protected>
                  <AnimatedSwitch
                    atEnter={bounceTransition.atEnter}
                    atLeave={bounceTransition.atLeave}
                    atActive={bounceTransition.atActive}
                    mapStyles={mapStyles}
                    className="route-wrapper"
                  >
                    <Route exact path={ROUTES.ONBOARDING}>
                      <AsyncOnboarding />
                    </Route>
                    <Route exact path={ROUTES.HOME_PATH}>
                      <AsyncHome />
                    </Route>

                    <Route exact path={ROUTES.DASHBOARD}>
                      <AsyncDashboard />
                    </Route>

                    <Route exact path={ROUTES.PLANNING}>
                      <Header
                        links={PLANNING_HEADER_LINKS}
                        backButton={false}
                        sticky={false}
                      />
                      <AsyncPlanning />
                    </Route>

                    <Route exact path={ROUTES.ASSISTANT}>
                      <Header
                        links={PLANNING_HEADER_LINKS}
                        backButton={false}
                        sticky={false}
                      />
                      <AsyncAssistant />
                    </Route>

                    <Route exact path={ROUTES.INVOICE}>
                      <AsyncInvoicing />
                    </Route>

                    <Route exact path={ROUTES.INVOICE_NEW}>
                      <HeaderBackButton />
                      <AsyncInvoicingNew />
                    </Route>

                    <Route exact path={ROUTES.INVOICE_EDIT}>
                      <HeaderBackButton />
                      <AsyncInvoicingNew />
                    </Route>

                    <Route exact path={ROUTES.INVOICE_ITEM}>
                      <HeaderBackButton />
                      <AsyncInvoicingItem />
                    </Route>

                    <Route exact path={ROUTES.INVOICE_HISTORY}>
                      <HeaderBackButton />
                      <AsyncInvoicingHistory />
                    </Route>

                    <Route exact path={ROUTES.TIME_ENTRY}>
                      <AsyncTimeEntry />
                    </Route>

                    <Route exact path={ROUTES.TIME_APPROVAL}>
                      <Header
                        links={APPROVAL_HEADER_LINKS}
                        backButton={false}
                        sticky={false}
                      />
                      <AsyncTimeApproval />
                    </Route>

                    <Route exact path={ROUTES.TIME_OFF_APPROVAL}>
                      <Header links={APPROVAL_HEADER_LINKS} sticky={false} />
                    </Route>

                    <Route exact path={ROUTES.REPORTS}>
                      <ReportingProvider>
                        <AsyncReports />
                      </ReportingProvider>
                    </Route>

                    <Route exact path={ROUTES.SETTINGS}>
                      <AsyncSettings />
                    </Route>

                    {/* Clients */}
                    <Route exact path={ROUTES.CLIENTS}>
                      <HeaderBackButton />
                      <AsyncClients />
                    </Route>

                    <Route exact path={ROUTES.CLIENTS_ADD}>
                      <HeaderBackButton />
                      <AsyncClientsAdd />
                    </Route>

                    <Route exact path={ROUTES.CLIENTS_EDIT}>
                      <HeaderBackButton />
                      <AsyncClientsAdd />
                    </Route>

                    {/* Projects */}
                    <Route exact path={ROUTES.PROJECTS}>
                      <HeaderBackButton />
                      <AsyncProjects />
                    </Route>

                    <Route exact path={ROUTES.PROJECTS_ADD}>
                      <HeaderBackButton />
                      <AsyncProjectsAdd />
                    </Route>

                    <Route exact path={ROUTES.PROJECTS_EDIT}>
                      <HeaderBackButton />
                      <AsyncProjectsAdd />
                    </Route>

                    {/* Members */}
                    <Route exact path={ROUTES.MEMBERS}>
                      <HeaderBackButton />
                      <AsyncMembers />
                    </Route>

                    <Route exact path={ROUTES.MEMBERS_ADD}>
                      <HeaderBackButton />
                      <AsyncMembersAdd />
                    </Route>

                    <Route exact path={ROUTES.MEMBERS_EDIT}>
                      <HeaderBackButton />
                      <AsyncMembersAdd />
                    </Route>

                    {/* Project roles */}
                    <Route exact path={ROUTES.PROJECT_ROLES}>
                      <HeaderBackButton />
                      <AsyncProjectRoles />
                    </Route>

                    {/* Tags */}
                    <Route exact path={ROUTES.TAGS}>
                      <HeaderBackButton />
                      <AsyncTags />
                    </Route>

                    {/* Profile */}
                    <Route exact path={ROUTES.PROFILE}>
                      <AsyncProfile />
                    </Route>

                    {/* Setting Subscription */}

                    <Route exact path={ROUTES.SUBSCRIPTION}>
                      <Header links={SUBSCRIPTION_HEADER_LINKS} isSubscription />
                      <AsyncSubscription />
                    </Route>

                    {/* <Route exact path={ROUTES.SUBSCRIPTION_BILLING}>
                            <AsyncSubscriptionBilling />
                          </Route> */}

                    <Route exact path={ROUTES.SUBSCRIPTION_INVOICES}>
                      <Header links={SUBSCRIPTION_HEADER_LINKS} isSubscription />
                      <AsyncSubscriptionInvoices />
                    </Route>

                    {/* Setting Company */}
                    <Route exact path={ROUTES.COMPANY}>
                      <HeaderBackButton />
                      <AsyncCompany />
                    </Route>

                    {/* Setting Integrations */}
                    <Route exact path={ROUTES.INTEGRATIONS}>
                      <HeaderBackButton />
                      <AsyncIntegrations />
                    </Route>

                    {/* Integrations Invoice history */}
                    <Route exact path={ROUTES.INTEGRATIONS_HISTORY}>
                      <HeaderBackButton />
                      <AsyncIntegrationsHistory />
                    </Route>

                    {/* Setting Activity log */}
                    <Route exact path={ROUTES.ACTIVITY_LOG}>
                      <HeaderBackButton />
                      <AsyncActivityLog />
                    </Route>

                    {/* Setting Invoice */}
                    <Route exact path={ROUTES.INVOICE_SETTING}>
                      <HeaderBackButton />
                      <AsyncInvoiceSetting />
                    </Route>
                    <Route exact path={ROUTES.FORBIDDEN}>
                      <HeaderBackButton />
                      <Forbidden />
                    </Route>
                    <Route path="*">
                      <HeaderBackButton />
                      <NoMatch />
                    </Route>
                  </AnimatedSwitch>
                </Protected>
              </Switch>
            </AppContainer>
          </Router>
        </SnackbarProvider>
      </ThemeProvider>
    </LanguageProvider>

    // </ErrorBoundary>
  )
}

const App = () => {
  const { REACT_APP_GA_TRACKING_ID } = process.env
  const initGa = () => {
    ReactGA.initialize(REACT_APP_GA_TRACKING_ID)
  }

  const handleAcceptCookie = () => {
    if (REACT_APP_GA_TRACKING_ID) {
      initGa()
    }
  }

  useEffect(() => {
    initGa()
  }, [])

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <GlobalProvider>
          <AuthProvider>
            <Elements stripe={stripePromise}>
              <RouterConfig />
              <CookieConsent
                onAccept={handleAcceptCookie}
                buttonText="Accept all Cookies"
                style={{
                  background: '#2e3a59',
                  fontSize: '18px',
                  width: '500px',
                  height: '200px',
                  flexFlow: 'unset',
                  borderRadius: '16px',
                  left: '50%',
                  transform: 'translate(-50%,-5%)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-around',
                  flexDirection: 'column'
                }}
                contentStyle={{ flex: 'unset', textAlign: 'center' }}
                buttonStyle={{
                  background: '#6b75ff',
                  borderRadius: '4px',
                  color: '#fff',
                  padding: '14px 24px',
                  fontSize: '18px'
                }}
              >
                This website uses cookies to enhance the user experience.Read more
                about our{' '}
                <a href="/cookie-policy" style={{ color: '#6f96e1' }}>
                  cookie policy
                </a>
              </CookieConsent>
            </Elements>
          </AuthProvider>
        </GlobalProvider>
      </PersistGate>
    </Provider>
  )
}

function Protected({ children }) {
  const loggedIn = isLoggedIn()
  const overview = useSelector(state => state.subscription.overview)
  const isUserFreezed = overview?.tenant_status === 2

  if (!loggedIn) return <Redirect to={ROUTES.SIGNIN} />

  if (isUserFreezed) return <Redirect to={ROUTES.DASHBOARD} />

  return children
}

Protected.propTypes = {
  children: PropTypes.node.isRequired
}

export default App
