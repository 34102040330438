/* eslint-disable react/prop-types */
import React, { useState, useContext } from 'react'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import cx from 'clsx'
import Button from '@material-ui/core/Button'
import { __RouterContext } from 'react-router'
import ROUTES from 'routes/routes'
import useTranslate from 'hooks/useTranslate'
import s from './SyncStatusBar.module.scss'

function SynchronizationStatusBar({ unseenSyncNotices, fortNoxSyncTimes }) {
  const { location } = useContext(__RouterContext)
  const history = useHistory()
  const currentUrl = location.pathname
  const t = useTranslate('dashboard.syncStatusBar')

  const getStatusBars = unseenSyncNotices => {
    const failed = unseenSyncNotices.filter(item => item.is_success === 0)
    const warning = unseenSyncNotices.filter(item => item.is_success === 1)

    const latestFailed = failed.length > 0 ? failed[failed.length - 1] : null
    const latestWarning = warning.length > 0 ? warning[warning.length - 1] : null

    if (latestFailed && latestWarning) {
      return {
        failed: latestFailed,
        warning: latestWarning,
        failedCount: failed.length
      }
    } else if (latestFailed) {
      return { failed: latestFailed, failedCount: failed.length }
    } else if (latestWarning) {
      return { warning: latestWarning }
    }

    return {}
  }

  const { failed, warning, failedCount } = getStatusBars(unseenSyncNotices)
  const moduleName = failed?.module_name
  const handleResync = () => {
    history.push(ROUTES.INTEGRATIONS)
  }

  return (
    <div>
      {failed && (
        <div className={s.failedSyncStatusBar}>
          <div
            className={cx(s.syncStatusContainer, {
              [s.narrowWidth]:
                currentUrl === '/settings/members' ||
                currentUrl === '/settings/clients'
            })}
          >
            <div className={s.textsContainer}>
              <span className={s.title}>{t(0)}</span>
              <p className={s.des}>
                <span>{failedCount > 1 ? t(1) : t(2)}</span>
                <span>
                  {failedCount === 1 &&
                    moment(failed.executed_at).format('MMMM D, YYYY [at] h:mm A')}
                </span>
              </p>
              {failedCount === 1 && (
                <p className={s.des}>
                  <span>{t(3)}</span>
                  <span>
                    {failedCount === 1 &&
                      fortNoxSyncTimes[moduleName]?.last_success_sync?.length > 0 &&
                      moment(fortNoxSyncTimes[moduleName]?.last_success_sync).format(
                        'MMMM D, YYYY [at] h:mm A'
                      )}
                  </span>
                </p>
              )}
            </div>

            <Button
              variant="text"
              // color="primary"
              onClick={handleResync}
              classes={{ root: s.btn, label: s.btnLabel }}
              // disabled={loading}
              data-testid="submit"
            >
              {t(4)}
            </Button>
          </div>
        </div>
      )}
      {warning && (
        <div className={s.warningStatusBar}>
          <div
            className={cx(s.syncStatusContainer, {
              [s.narrowWidth]:
                currentUrl === '/settings/members' ||
                currentUrl === '/settings/clients'
            })}
          >
            <div className={s.textsContainer}>
              <span className={s.title}>{t(5)}</span>
              <span className={s.des}>
                {t(6)}
                {warning?.module_name}
                {t(7)}
              </span>
            </div>

            <div>
              <Button
                variant="text"
                // color="primary"
                onClick={handleResync}
                classes={{ root: s.btn, label: s.btnLabel }}
                // disabled={loading}
                data-testid="submit"
              >
                {t(8)}
              </Button>
              <Button
                variant="contained"
                // color="primary"
                onClick={handleResync}
                classes={{ root: s.reviewUpdatesBtn, label: s.reviewUpdatesBtnLabel }}
                // disabled={loading}
                data-testid="submit"
              >
                {t(9)}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default SynchronizationStatusBar
