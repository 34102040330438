/* eslint-disable react/prop-types */
import React from 'react'

import { Button } from '@material-ui/core'
import moment from 'moment'
import commaNumber from 'comma-number'
import Loading from 'components/Loading'
import useTranslate from 'hooks/useTranslate'
import s from './UpdateSubscription.module.scss'

function ConfirmModal({
  onCancel,
  onSubmit,
  data,
  isLoadingModal,
  isSwitchYearly,
  currency
}) {
  const t = useTranslate('subscription')

  return (
    <div className={s.modalWarning}>
      {isLoadingModal ? (
        <Loading show className={s.loading} />
      ) : (
        <>
          {isSwitchYearly ? (
            <>
              <span className={s.text}>
                {t(105)}
                <strong>
                  {commaNumber(data.proration)} {currency}
                </strong>
              </span>
              <div className={s.btnsModal}>
                <Button
                  color="primary"
                  variant="text"
                  onClick={onCancel}
                  className={s.cancelBtn}
                  data-testid="cancel-change"
                >
                  {t(106)}
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={isLoadingModal}
                  onClick={onSubmit}
                  className={s.submitBtnModal}
                  data-testid="confirm"
                >
                  {isSwitchYearly ? t(107) : t(108)}
                </Button>
              </div>
            </>
          ) : (
            <>
              {data.isSmallerCount ? (
                <>
                  <span className={s.text}>
                    {t(109)}
                    <strong>{data.current}</strong>
                    {t(115)}
                    <strong>{data.updated}</strong>.
                    <br />
                    {t(110)}
                    <strong>{data.date}</strong>
                    {t(111)}
                    <strong>
                      {commaNumber(data.amount)} {currency}
                    </strong>
                    .
                  </span>
                  <div className={s.btnsModal}>
                    <Button
                      color="primary"
                      variant="text"
                      onClick={onCancel}
                      className={s.cancelBtn}
                      data-testid="cancel-change"
                    >
                      {t(106)}
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      disabled={isLoadingModal}
                      onClick={onSubmit}
                      className={s.submitBtnModal}
                      data-testid="confirm"
                    >
                      {t(108)}
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  {' '}
                  <span className={s.text}>
                    {t(112)} <strong>{data.addedCount}</strong>
                    {t(113)}
                    <strong>{moment(new Date()).format('MMM DD, YYYY')}</strong>
                    {t(111)}
                    <strong>
                      {commaNumber(data.proration)} {currency}
                    </strong>
                    {/* {t(114)}
                    <strong>{data.date}</strong>
                    {t(111)}
                    <strong>{commaNumber(data.amount)} SEK</strong> */}
                  </span>
                  <div className={s.btnsModal}>
                    <Button
                      color="primary"
                      variant="text"
                      onClick={onCancel}
                      className={s.cancelBtn}
                      data-testid="cancel-change"
                    >
                      {t(106)}
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      disabled={isLoadingModal}
                      onClick={onSubmit}
                      className={s.submitBtnModal}
                      data-testid="confirm"
                    >
                      {t(107)}
                    </Button>
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  )
}

export default ConfirmModal
