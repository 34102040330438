/* eslint-disable react/prop-types */
import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash.isempty'
import cx from 'clsx'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import Dropdown from '../Dropdown'
import Loading from '../Loading'
import Checkbox from '../Checkbox'

import s from './DropdownSearch.module.scss'

function DropdownSearch({
  label,
  placeholder,
  items,
  onSelect,
  value,
  classes,
  loading,
  search,
  multiple,
  defaultIsAll,
  disableItems,
  popoverAlign,
  disabled,
  ownerInfo,
  selectedSyncOption
}) {
  const dropdown = useRef(null)
  const [searchText, setSearchText] = useState('')

  /**
   * handle select item
   * @param {number} id | selected item id
   */
  const handleSelect = id => event => {
    onSelect(id, event.target.checked)

    if (!multiple) {
      dropdown.current.handleClose()
    }
  }

  /**
   * handle search input change
   * @param {*} e | native event
   */
  const handleSearch = e => {
    setSearchText(e.target.value)
  }

  /**
   * clear search text
   */
  const clearSearch = () => setSearchText('')

  const Content = (
    <div className={s.content}>
      {loading ? (
        <Loading show className={s.loading} />
      ) : (
        <div>
          {search && (
            <div className={s.searchBox}>
              <div className={s.searchIcon}>
                <div className="icon-Search" />
              </div>
              <input
                value={searchText}
                onChange={handleSearch}
                // eslint-disable-next-line prefer-template
                placeholder={`${label ? 'Search for ' + label : 'Search'}`}
              />
              {!isEmpty(searchText) && (
                <button className={s.clearSearch} type="button" onClick={clearSearch}>
                  <div className="icon-Close" />
                </button>
              )}
            </div>
          )}

          <div className={s.list}>
            <FormGroup row>
              {multiple && (
                <FormControlLabel
                  classes={{
                    root: cx(s.formControlLabel, s.selectAllFormControl),
                    label: cx(s.label, s.selectAllLabel)
                  }}
                  disabled={items.length === 0}
                  data-testid="dropDownItem"
                  control={
                    <Checkbox
                      checked={value.length > 0}
                      onChange={handleSelect(-1)}
                      value="Select all"
                      color="primary"
                      checkedIconName={value.length ? 'Minus' : 'Check'}
                    />
                  }
                  label={`${value.length ? 'Unselect' : 'Select'} all`}
                />
              )}

              {items
                .filter(item => {
                  if (isEmpty(searchText)) return item
                  return (
                    item.title.toLowerCase().includes(searchText.toLowerCase()) ||
                    (item.email &&
                      item.email.toLowerCase().includes(searchText.toLowerCase())) ||
                    item.selected
                  )
                })
                .map(item => (
                  <FormControlLabel
                    key={item.title}
                    data-testid="dropDownItem"
                    disabled={disableItems?.find(el => el === item.title)}
                    classes={{
                      root: cx(
                        s.formControlLabel,
                        {
                          [s.formControlLabelWithMargin]:
                            item.id !== null && item.id !== -1
                        },
                        {
                          [s.selectedFormControlLabel]:
                            !multiple && item.title === value
                        },

                        {
                          [s.selectedAsNew]: item.id === null
                        },
                        {
                          [s.excludeFromSync]: item.id === -1
                        }
                      ),
                      label: s.label
                    }}
                    control={
                      multiple ? (
                        <Checkbox
                          checked={item.selected}
                          onChange={handleSelect(item.id)}
                          value={item.title}
                          color="primary"
                        />
                      ) : (
                        <button
                          className={s.hidden}
                          type="button"
                          onClick={handleSelect(item.id)}
                        />
                      )
                    }
                    label={
                      <div
                        className={cx({
                          [s.disabledLabel]: disableItems?.includes(item.title)
                        })}
                      >
                        <span className={s.value}>{item.title}</span>
                        {/* Show email if title is not "Add As New Client" or "Exclude From The Sync" */}
                        {item.id !== null && item.id !== -1 && item.email ? (
                          <span className={s.email}>{item.email}</span>
                        ) : null}
                      </div>
                    }
                  />
                ))}
            </FormGroup>
          </div>
        </div>
      )}
    </div>
  )

  let inputContent

  if (multiple) {
    inputContent = isEmpty(value) ? (
      defaultIsAll && <span className={s.value}>All</span>
    ) : (
      <span className={s.value}>{value.join(', ')}</span>
    )
  } else {
    const selectedItem = items.find(item => item.title === value)

    inputContent = isEmpty(value) ? null : selectedItem ? (
      <>
        {selectedSyncOption !== undefined &&
        selectedSyncOption === 'members' &&
        selectedItem?.id === ownerInfo?.id ? (
          <div className={s.tenantOwner}>
            <span>TENANT OWNER</span>
          </div>
        ) : null}
        <span className={s.value}>{selectedItem.title}</span>
        {selectedItem?.id !== null &&
        selectedItem?.id !== -1 &&
        selectedItem?.email ? (
          <span className={s.email}>{selectedItem?.email}</span>
        ) : null}
      </>
    ) : null
  }

  return (
    <Dropdown
      ref={dropdown}
      title={label}
      placeholder={placeholder}
      classes={{
        root: cx(s.root, classes.root),
        popover: cx(s.popover, classes.popover),
        input: s.input,
        ...classes
      }}
      content={Content}
      inputContent={inputContent}
      popoverAlign={popoverAlign}
      disabled={disabled}
      testid="dropdown"
    />
  )
}

DropdownSearch.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      title: PropTypes.string,
      selected: PropTypes.bool
    })
  ).isRequired,
  onSelect: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  classes: PropTypes.shape({
    root: PropTypes.string,
    popover: PropTypes.string
  }),
  loading: PropTypes.bool,
  search: PropTypes.bool,
  multiple: PropTypes.bool,
  defaultIsAll: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  popoverAlign: PropTypes.object
}

DropdownSearch.defaultProps = {
  label: '',
  placeholder: '',
  value: null,
  classes: {
    root: '',
    popover: ''
  },
  onSelect: () => {},
  loading: false,
  search: true,
  multiple: true,
  defaultIsAll: true,
  popoverAlign: {
    vertical: 'top',
    horizontal: 'center'
  }
}

export default DropdownSearch
