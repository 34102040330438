/* eslint-disable react/require-default-props */
/* eslint-disable no-return-assign */
/* eslint-disable react/jsx-indent */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import PaymentForm from '../PaymentForm'

import APIs from 'api'
import PaymentInvoice from '../PaymentInvoice'
import s from './PaymentPlan.module.scss'

function PaymentModal({ isFromSubscription, onClose, tenantInfo, selectedPrice }) {
  const { last_pending_demand: lastPendingDemand } = tenantInfo
  const isProPlan = lastPendingDemand.next_plan_type === 'Pro'
  const [showInvoice, setShowInvoice] = useState(false)
  const [invoiceId, setInvoiceId] = useState(null)

  const handleCancel = () => {
    // APIs.cancelDemands()
    onClose()
    // window.location.reload()
  }

  const handleClose = () => {
    // APIs.cancelDemands()
    onClose()
    // window.location.reload()
  }

  const handlePayment = async (id, customerInfo) => {
    const info = {
      payment_method: id,
      plan_id: lastPendingDemand.next_plan_id,
      number_of_members: lastPendingDemand.quantity,
      pay_by: 'stripe',
      first_name: customerInfo.firstName,
      last_name: customerInfo.lastName,
      country: customerInfo.country,
      postal_code: customerInfo.postalCode
    }
    const subscriptionInfo = {
      payment_method_id: null,
      plan_id: lastPendingDemand.next_plan_id,
      number_of_members: lastPendingDemand.quantity
    }

    await APIs.addNewPaymentMethod({ body: info }).then(
      res => (subscriptionInfo.payment_method_id = res.data.id)
    )
    return APIs.checkoutSubscription({ body: subscriptionInfo }).then(res => {
      setInvoiceId(res.data.id)
      setShowInvoice(true)
    })
  }

  return (
    <>
      {showInvoice ? (
        <PaymentInvoice
          invoiceId={invoiceId}
          tenantInfo={tenantInfo}
          selectedPrice={selectedPrice}
          onClose={handleClose}
          isFromSubscription={isFromSubscription}
        />
      ) : (
        <div className={s.wrapper}>
          <div className={s.head}>
            <div className={s.titleBisFlow}>
              <span className={s.part1}>Bis</span>
              <span className={s.part2}>Flow</span>{' '}
              {/* <span className={s.part3}>{lastPendingDemand.next_plan_type}</span> */}
            </div>
            <p className={s.description}>
              Dear {tenantInfo.name},<br /> You requested a{' '}
              <span>{lastPendingDemand?.next_plan_type}</span> plan{' '}
              <span>({lastPendingDemand?.billing_option.title})</span> with{' '}
              <span>{lastPendingDemand.quantity}</span> of members with{' '}
              <span>
                {isProPlan ? selectedPrice.amount : lastPendingDemand.unit_amount}
              </span>{' '}
              <span>
                {isProPlan ? selectedPrice.currency : lastPendingDemand.currency}
              </span>{' '}
              for each member. You have to pay{' '}
              <span>
                {isProPlan
                  ? (selectedPrice.amount * lastPendingDemand.quantity).toFixed(2)
                  : lastPendingDemand.price}
              </span>{' '}
              <span>
                {isProPlan ? selectedPrice.currency : lastPendingDemand.currency}
              </span>{' '}
              and then forward to onboarding.
            </p>
          </div>

          <div className={s.head}>
            <h2 className={s.title}>Billing information:</h2>
          </div>

          <PaymentForm
            tenantInfo={tenantInfo}
            selectedPrice={selectedPrice}
            handlePayment={handlePayment}
            onCancel={handleCancel}
          />
        </div>
      )}
    </>
  )
}

PaymentModal.propTypes = {
  isFromSubscription: PropTypes.bool,
  onClose: PropTypes.func.isRequired
}

export default PaymentModal
