/* eslint-disable react/require-default-props */
import { Button } from '@material-ui/core'
import commaNumber from 'comma-number'
import APIs from 'api'
import Loading from 'components/Loading'
import SnackManager from 'libs/snack'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ROUTES from 'routes/routes'
import s from './PaymentInvoice.module.scss'

function PaymentInvoice({
  tenantInfo,
  invoiceId,
  isFromSubscription,
  onClose,
  selectedPrice
}) {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)
  const { last_pending_demand: lastPendingDemand } = tenantInfo
  const isProPlan = lastPendingDemand.next_plan_type === 'Pro'

  const maxRetries = 10
  let retryCount = 0

  useEffect(() => {
    if (invoiceId) {
      const fetchInvoice = async () => {
        try {
          const res = await APIs.getSubscriptionInvoice({ params: { id: invoiceId } })
          setData(res.data)
          setLoading(false)
          clearInterval(invoiceInterval)
        } catch (err) {
          if (err.response?.status === 404 && retryCount < maxRetries) {
            retryCount++
          } else if (typeof err === 'string') {
            SnackManager.error(err)
          } else if (err?.message) {
            SnackManager.error(err.message)
          } else if (err && typeof err === 'object') {
            Object.entries(err).forEach(([key, messages]) => {
              messages.forEach(message => {
                SnackManager.error(message)
              })
            })
          } else {
            SnackManager.error('An error occurred. Please try again.')
            setLoading(false)
            clearInterval(invoiceInterval)
          }
        }
      }

      const invoiceInterval = setInterval(fetchInvoice, 5000)

      return () => clearInterval(invoiceInterval)
    }
  }, [invoiceId])

  const handleSkip = () => {
    window.history.pushState(null, null, ROUTES.BASE)
    window.location.reload()
  }

  const handleClose = () => {
    onClose()
    if (isFromSubscription) window.location.reload()
  }

  return (
    <>
      {data ? (
        <div className={s.wrapper}>
          <div className={s.brand}>
            <span className={s.bis}>Bis</span>
            <span className={s.flow}>Flow</span>
          </div>
          <div className={s.title}>
            <span className={s.head}>Receipt of Payment</span>
            <span className={s.number}>Invoice number : {data.invoice_number}</span>
            <span className={s.date}>Date: {data.created_at}</span>
          </div>
          <div className={s.paidDate}>
            <div className={s.paidTitle}>
              <span className={s.headDate}>DATE PAID</span>
              <span className={s.headCard}>CARD NUMBER</span>
            </div>
            <div className={s.paidInfo}>
              <span className={s.date}>{data.paid_date ? data.paid_date : ''}</span>
              <span className={s.card}>*********{data.card_last_four}</span>
            </div>
          </div>
          <div className={s.details}>
            <div className={s.firstLine}>
              <span className={s.firstLineHead}>
                BisFlow {lastPendingDemand.next_plan_type} Subscription
              </span>
              <div className={s.fInfo}>
                <span className={s.members}>
                  {lastPendingDemand.quantity} members
                </span>
                {/* <span className={s.date}>{lastPendingDemand.next_plan_type}</span> */}
              </div>
            </div>
            <div className={s.secondLine}>
              <div className={s.sInfo}>
                <span className={s.infoTitle}>Amount paid</span>
                <span className={s.amount}>
                  {commaNumber(
                    isProPlan
                      ? (selectedPrice.amount * lastPendingDemand.quantity).toFixed(2)
                      : lastPendingDemand.price
                  )}{' '}
                  {isProPlan ? selectedPrice.currency : lastPendingDemand.currency}
                </span>
              </div>
              <span className={s.calc}>
                {isProPlan ? selectedPrice.amount : lastPendingDemand.unit_amount}
                {isProPlan
                  ? selectedPrice.currency
                  : lastPendingDemand.currency} x {lastPendingDemand.quantity} Members{' '}
              </span>
            </div>
          </div>
          <div className={s.contact}>
            <a href={data.download_link} className={s.download}>
              Download PDF
            </a>
            <span className={s.contactDetails}>
              If you have any questions, contact BisFlow at{' '}
              <span>support@bisflow.com</span> or call at <span>+46701622602</span>
            </span>
          </div>
          <div
            className={s.btns}
            style={{ justifyContent: isFromSubscription ? 'center' : 'flex-end' }}
          >
            {!isFromSubscription ? (
              <Button
                variant="outlined"
                color="primary"
                classes={{ root: s.skip }}
                onClick={handleSkip}
                disabled
              >
                Skip Set up
              </Button>
            ) : null}
            <Button
              variant="contained"
              color="primary"
              classes={{ root: s.continue }}
              onClick={handleClose}
              data-testid="close"
            >
              {!isFromSubscription ? `continue to Set up` : 'close'}
            </Button>
          </div>
        </div>
      ) : (
        <Loading show className={s.loading} />
      )}
    </>
  )
}

PaymentInvoice.propTypes = {
  invoiceId: PropTypes.number.isRequired,
  isFromSubscription: PropTypes.bool,
  onClose: PropTypes.func.isRequired
}
export default PaymentInvoice
